









































































































































































.index-list
  height calc(100vh - 279px)
  @media (min-width 768px)
    padding 0 3rem
    height calc(100vh - 271px)
  .row-item:first-child
    margin-top 16px

.col-head
  display: flex;
  justify-content: space-between;
  padding-right 40px

.tools
  width 50%

.index-head, .member
  @media (min-width 1024px)
    width 75%!important

.row-item
  width 100%

.member
  max-height 82px
  position relative
  //transition background-color .5s

.search
  margin-bottom 10px
  @media (min-width: 768px)
    margin-bottom 0px

.btn-create-member
  @media (max-width 768px)
    top 75px!important

