.index-list {
  height: calc(100vh - 279px);
}
@media (min-width: 768px) {
  .index-list {
    padding: 0 3rem;
    height: calc(100vh - 271px);
  }
}
.index-list .row-item:first-child {
  margin-top: 16px;
}
.col-head {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.tools {
  width: 50%;
}
@media (min-width: 1024px) {
  .index-head,
  .member {
    width: 75% !important;
  }
}
.row-item {
  width: 100%;
}
.member {
  max-height: 82px;
  position: relative;
}
.search {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .search {
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .btn-create-member {
    top: 75px !important;
  }
}
/*# sourceMappingURL=src/pages/members/list/index.css.map */